'use client'

import { useUnit } from 'effector-react'
import { useParams, usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { navigationStateUpdated } from '~/features/navigation/model'

export const NavigationEvents = () => {
  const updated = useUnit(navigationStateUpdated)
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const params = useParams()

  useEffect(() => {
    updated({
      pathname,
      params,
      searchParams,
      url: new URL(window.location.href),
    })
  }, [params, pathname, searchParams, updated])

  return null
}
