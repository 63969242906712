import { createEvent, createStore, restore, sample } from 'effector'
import { createGate } from 'effector-react'
import type { Params } from 'next/dist/shared/lib/router/utils/route-matcher'
import type { ReadonlyURLSearchParams, useRouter } from 'next/navigation'

export const AppRouterGate = createGate<ReturnType<typeof useRouter> | null>({
  defaultState: null,
})

export const $appRouterInstance = restore(AppRouterGate.open, null)

type NavigationState = {
  pathname: string | null
  params: Params
  searchParams: ReadonlyURLSearchParams | null
  url: URL | null
}

type NavigationUpdatePayload = {
  pathname: string
  params: Params
  searchParams: ReadonlyURLSearchParams
  url: URL
}

export const $navigationState = createStore<NavigationState>({
  pathname:
    typeof window !== 'undefined'
      ? new URL(window.location.href).pathname
      : null,
  params: {},
  searchParams: null,
  url: typeof window !== 'undefined' ? new URL(window.location.href) : null,
})

export const navigationStateUpdated = createEvent<NavigationUpdatePayload>()

export const navigationStateChanged = createEvent<{
  from: NavigationState
  to: NavigationState
}>()

sample({
  clock: navigationStateUpdated,
  source: $navigationState,
  filter: (state, newState) => state.pathname !== newState.pathname,
  fn: (state, newState) => ({ from: state, to: newState }),
  target: navigationStateChanged,
})

sample({
  clock: navigationStateUpdated,
  target: $navigationState,
})
