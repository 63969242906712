import(/* webpackMode: "eager", webpackExports: ["EffectorNext"] */ "/app/node_modules/.pnpm/@effector+next@0.7.0_effector-react@23.2.1_effector@23.2.2_react@18.3.1__effector@23.2.2_react@18.3.1/node_modules/@effector/next/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/node_modules/.pnpm/next-themes@0.3.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.12_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.12_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Manrope\",\"arguments\":[{\"subsets\":[\"cyrillic-ext\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"variable\":\"--font-manrope\"}],\"variableName\":\"manrope\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.12_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"cyrillic-ext\"],\"weight\":[\"600\"],\"variable\":\"--font-montserrat\"}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/handsontable@14.5.0/node_modules/handsontable/dist/handsontable.full.min.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/keen-slider@6.8.6/node_modules/keen-slider/keen-slider.min.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-day-picker@8.10.1_date-fns@4.1.0_react@18.3.1/node_modules/react-day-picker/dist/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/.pnpm/sonner@1.5.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ApplicationGate"] */ "/app/src/app/_layout/application-gate.ts");
;
import(/* webpackMode: "eager", webpackExports: ["ClientRouterGate"] */ "/app/src/app/_layout/client-router-gate.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationEvents"] */ "/app/src/app/_layout/navigation-events.ts");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/app/src/app/_layout/tooltip-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["DebugTools"] */ "/app/src/processes/debug-tools.ts");
;
import(/* webpackMode: "eager", webpackExports: ["YandexMetrika"] */ "/app/src/processes/yandex-metrika/index.tsx");
