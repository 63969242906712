'use client'

import { useGate } from 'effector-react'
import { useRouter } from 'next/navigation'
import { AppRouterGate } from '~/features/navigation/model'

export const ClientRouterGate = () => {
  const router = useRouter()
  useGate(AppRouterGate, router)
  return null
}
