import { env } from './env.mjs'

export const flags = {
  isPreview:
    process.env.NODE_ENV === 'development' || env.NEXT_PUBLIC_PRODUCTION !== 1,
  future: {
    trafficV2: true,
  },
  debug: {
    effectorScope: false,
  },
} as const
