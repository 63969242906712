import { sample } from 'effector'
import { createGate } from 'effector-react'
import { once } from 'patronum'
import { setupWebApis } from '~/features/web_apis'
import { initUser } from '~/user/model'

export const AppGate = createGate()

sample({
  clock: once(AppGate.open),
  target: [setupWebApis, initUser] as const,
})
