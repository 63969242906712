'use client'

import { env } from '~/env.mjs'
import { useNavigation } from './use-navigation'

export function YandexMetrika() {
  useNavigation({
    on: {
      routeChanged: ({ url }) => {
        if (window.ym && typeof ym === 'function') {
          ym(env.NEXT_PUBLIC_YANDEX_METRIKA_KEY, 'hit', url)
        }
      },
    },
  })

  return null
}
